import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProfileAction,
  SiteTableHeader,
  User,
  UserDetailsInfo,
  UserInfo,
  UserSite,
} from "../interfaces/user.interface";
import {
  CreateUser,
  FetchUserDetails,
  FetchUserGroupsDetails,
  FetchUserSitesDetails,
  UpdateUserDetails,
  UpdateUserGroupsDetails,
} from "./user.thunk.tsx";
import { Group } from "../interfaces/auth.interface.tsx";

export interface UserState {
  submitting: boolean;
  errorSubmitting: string | null;
  currentUser: {
    userInfo: UserInfo;
    userGroups: string[];
    userSites: UserSite[];
  };
  fetchingUser: "IDLE" | "DONE" | "FAILED" | "PENDING";
  users: User[];
  errorFetchingUser: string | null;
  filters: {
    searchTerm: string;
    searchBy: string;
  };
  loaders: {
    creatingUser: boolean;
    updatingUser: boolean;
  };
  errors: {
    creatingUser: string | undefined | null;
    updatingUser: string | undefined | null;
  };

  // user selected
  selectedUser: {
    loading: "IDLE" | "PENDING" | "DONE";
    error: string | null | undefined;
    data: {
      userInfo: UserDetailsInfo | null;
      userSites: any;
      userGroups: number[];
    };
    profileActions: ProfileAction[];
    siteTableHeaders: SiteTableHeader[];
    loadingUserSite: "IDLE" | "PENDING" | "DONE";
    loadingUserSitesError: string | null | undefined;
    loadingUserGroup: "IDLE" | "PENDING" | "DONE";
    creatingUser: boolean;
    loadingUserGroupError: string | null | undefined;
  };
}

export const createUserInfo = createAsyncThunk(
  "createUserInfo",
  async (payload: { data: UserInfo; token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users`,
      {
        method: "POST",
        body: JSON.stringify(payload.data),
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async (payload: { token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const fetchUserFacilities = createAsyncThunk(
  "fetchUserFacilities",
  async (payload: { token: string; user_id: string; groups }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/facilities`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const UpdateUserSite = createAsyncThunk(
  "UpdateUserSite",
  async (payload: {
    token: string;
    user_id: string;
    role_id: number;
    facility_id: number;
  }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/sites`,
      {
        method: "PUT",
        headers: { Authorization: payload.token },
        body: JSON.stringify({
          facility_id: payload.facility_id,
          role_id: payload.role_id,
        }),
      }
    );
    return response.json();
  }
);

export const DeleteUserSite = createAsyncThunk(
  "DeleteUserSite",
  async (payload: {
    token: string;
    user_id: string;
    role_id: number;
    facility_id: number;
  }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/sites`,
      {
        method: "DELETE",
        headers: { Authorization: payload.token },
        body: JSON.stringify({
          facility_id: payload.facility_id,
          role_id: payload.role_id,
        }),
      }
    );
    return response.json();
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: {
    submitting: false,
    errorSubmitting: null,
    currentUser: {
      userInfo: {
        first_name: "",
        last_name: "",
        middle_name: "",
        active: true,
        email_address: "",
        role_id: "10165",
      },
      userGroups: [],
      userSites: [],
    },
    users: [],
    fetchingUser: "IDLE",
    errorFetchingUser: null,
    fetchingUserGroups: "IDLE",
    errorFetchingUserGroup: null,
    updatingGroup: false,
    errorUpdatingGroup: null,
    loadingUserSites: "IDLE",
    filters: {
      searchBy: "first_name",
      searchTerm: "",
    },

    // Loaders
    loaders: {
      creatingUser: false,
      updatingUser: false,
    },

    // Errors
    errors: {
      creatingUser: null,
      updatingUser: null,
    },

    // selected user initial value
    selectedUser: {
      loading: "IDLE",
      loadingUserSite: "IDLE",
      loadingUserSitesError: null,
      loadingUserGroup: "IDLE",
      loadingUserGroupError: null,
      creatingUser: false,
      error: null,
      data: {
        userInfo: null,
        userSites: null,
        userGroups: [],
      },
      profileActions: [
        { name: "back-to-list", friendlly: "Back to list", iconName: "back" },
        { name: "edit", friendlly: "Edit", iconName: "edit" },
      ],
      siteTableHeaders: [
        { key: "group_id", dataIndex: "group_id", label: "Group", actions: [] },
        { key: "site", dataIndex: "site", label: "Site", actions: [] },
        { key: "role", dataIndex: "role", label: "Role", actions: [] },
        {
          key: "actions",
          dataIndex: "actions",
          label: "Action(s)",
          actions: ["delete"],
        },
      ],
    },
  } as UserState,
  reducers: {
    clearCurrentUser: (state) => {
      state.currentUser = {
        userInfo: {
          first_name: "",
          last_name: "",
          middle_name: "",
          active: true,
          email_address: "",
          role_id: "10165",
        },
        userGroups: [],
        userSites: [],
      };
    },
    setCurrentUser: (state, action) => {
      state.currentUser = { ...state.currentUser, ...action.payload };
    },
    setUserGroup: (state, action) => {
      state.currentUser.userGroups = action.payload;
    },
    setFilterSearchTerm: (state, action) => {
      state.filters.searchTerm = action.payload;
    },
    setFilterSearchBy: (state, action) => {
      state.filters.searchBy = action.payload;
    },
    clearSelectedUser: (state) => {
      state.selectedUser.loading = "IDLE";
      state.selectedUser.data = {
        userInfo: null,
        userGroups: [],
        userSites: [],
      };
      state.selectedUser.error = null;
      state.selectedUser.loadingUserGroup = "IDLE";
      state.selectedUser.loadingUserSite = "IDLE";
    },
    SetSelectedUserSites: (state, action) => {
      state.selectedUser.data.userSites = action.payload;
    },
    SetSelectedUserDetails: (state, action) => {
      state.selectedUser.data.userInfo = {
        ...state.selectedUser.data.userInfo,
        ...action.payload,
      };
      state.users = state.users.map((user) => {
        if (user.user_id === action.payload.user_id) {
          return {
            ...user,
            ...action.payload,
          };
        }
        return user;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // Getting user information details
      .addCase(FetchUserDetails.pending, (state) => {
        state.selectedUser.loading = "PENDING";
        state.selectedUser.error = null;
        state.selectedUser.data.userInfo = null;
      })
      .addCase(FetchUserDetails.rejected, (state, action) => {
        state.selectedUser.loading = "DONE";
        state.selectedUser.error = action.error.message;
        state.selectedUser.data.userInfo = null;
      })
      .addCase(FetchUserDetails.fulfilled, (state, action) => {
        state.selectedUser.loading = "DONE";
        state.selectedUser.error = null;
        state.selectedUser.data.userInfo = action.payload;
      })
      // Get user sites
      .addCase(FetchUserSitesDetails.pending, (state) => {
        state.selectedUser.loadingUserSite = "PENDING";
        state.selectedUser.loadingUserSitesError = null;
        state.selectedUser.data.userSites = null;
      })
      .addCase(FetchUserSitesDetails.rejected, (state, action) => {
        state.selectedUser.loadingUserSite = "DONE";
        state.selectedUser.loadingUserSitesError = action.error.message;
        state.selectedUser.data.userSites = null;
      })
      .addCase(FetchUserSitesDetails.fulfilled, (state, action) => {
        state.selectedUser.loadingUserSite = "DONE";
        state.selectedUser.loadingUserSitesError = null;
        state.selectedUser.data.userSites = action.payload;
      })
      // Get user Groups
      .addCase(FetchUserGroupsDetails.pending, (state) => {
        state.selectedUser.loadingUserGroup = "PENDING";
        state.selectedUser.loadingUserGroupError = null;
        state.selectedUser.data.userGroups = [];
      })
      .addCase(FetchUserGroupsDetails.rejected, (state, action) => {
        state.selectedUser.loadingUserGroup = "DONE";
        state.selectedUser.loadingUserGroupError = action.error.message;
        state.selectedUser.data.userGroups = [];
      })
      .addCase(FetchUserGroupsDetails.fulfilled, (state, action) => {
        state.selectedUser.loadingUserGroup = "DONE";
        state.selectedUser.loadingUserGroupError = null;
        state.selectedUser.data.userGroups = action.payload.map(
          (g: Group) => g.group_id
        );
      })
      // Update user Groups
      .addCase(UpdateUserGroupsDetails.pending, (state) => {
        state.selectedUser.loadingUserGroup = "PENDING";
        state.selectedUser.loadingUserGroupError = null;
      })
      .addCase(UpdateUserGroupsDetails.rejected, (state, action) => {
        state.selectedUser.loadingUserGroup = "DONE";
        state.selectedUser.loadingUserGroupError = action.error.message;
      })
      .addCase(UpdateUserGroupsDetails.fulfilled, (state, action) => {
        state.selectedUser.loadingUserGroup = "DONE";
        state.selectedUser.loadingUserGroupError = null;
        state.selectedUser.data.userGroups = action.payload.map(
          (g: Group) => g.group_id
        );
      })
      // Create user
      .addCase(CreateUser.pending, (state) => {
        state.loaders.creatingUser = true;
        state.errors.creatingUser = null;
      })
      .addCase(CreateUser.rejected, (state, action) => {
        state.loaders.creatingUser = false;
        state.errors.creatingUser = action.payload as string;
      })
      .addCase(CreateUser.fulfilled, (state, action) => {
        state.loaders.creatingUser = false;
        state.errors.creatingUser = null;
        state.selectedUser.data.userInfo = action.payload;
        state.users.push(action.payload);
      })
      // Update user
      .addCase(UpdateUserDetails.pending, (state) => {
        state.loaders.updatingUser = true;
        state.errors.updatingUser = null;
      })
      .addCase(UpdateUserDetails.rejected, (state, action) => {
        state.loaders.updatingUser = false;
        state.errors.updatingUser = action.error.message;
      })
      .addCase(UpdateUserDetails.fulfilled, (state, action) => {
        state.loaders.updatingUser = false;
        state.errors.updatingUser = null;
        state.selectedUser.data.userInfo = action.payload;
      })

      .addCase(fetchUsers.pending, (state) => {
        state.fetchingUser = "PENDING";
        state.errorFetchingUser = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.errorFetchingUser = action.error.message ?? null;
        state.fetchingUser = "FAILED";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.code ? [] : action.payload;
        state.fetchingUser = "DONE";
      });
  },
});
export const {
  setCurrentUser,
  setUserGroup,
  setFilterSearchBy,
  setFilterSearchTerm,
  clearCurrentUser,
  clearSelectedUser,
  SetSelectedUserSites,
  SetSelectedUserDetails,
} = UserSlice.actions;
export const reducer = UserSlice.reducer;
