import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../interfaces/user.interface";
import { UserSite } from "./user.reducer";

export interface FormState {
  user: {
    userInfo: UserInfo;
    groups: {
      data: string[];
      loading: "IDLE" | "LOADING" | "DONE";
    };
    sites: {
      data: UserSite[];
      loading: "IDLE" | "LOADING" | "DONE";
      userFacilities: any[];
    };
  };
  allFacilities: any[];
  loadingFacilities: "IDLE" | "LOADING" | "DONE";
}

const formsSlice = createSlice({
  name: "forms",
  initialState: {
    loading: true,
    user: {
      userInfo: {
        email_address: "",
        first_name: "",
        last_name: "",
        active: true,
        middle_name: "",
        role_id: "",
        user_id: null,
      },
      groups: {
        data: [],
        loading: "IDLE",
      },
      sites: {
        data: [],
        loading: "IDLE",
        userFacilities: [],
      },
    },
    allFacilities: [],
    loadingFacilities: "IDLE",
  } as FormState,
  reducers: {
    updateUserSite: (state: FormState, action: PayloadAction<any>) => {
      const { adding, site } = action.payload;
      if (adding) {
        state.user.sites.data.push(site);
      } else {
        state.user.sites.data = state.user.sites.data.filter(
          (s) => s.group_id === site.group_id && s.role_id === site.role_id
        );
      }
    },
    setAllFacilities: (state: FormState, action: PayloadAction<any[]>) => {
      state.allFacilities = action.payload;
      state.loadingFacilities = "DONE";
    },
    setSelectedUserSites: (
      state: FormState,
      action: PayloadAction<UserSite[]>
    ) => {
      state.user.sites.data = action.payload;
      state.user.sites.loading = "DONE";
    },
    updateUserInfoField: (
      state: FormState,
      action: PayloadAction<{ field: string; value: any }>
    ) => {
      if (state.user.userInfo) {
        state.user.userInfo[action.payload.field] = action.payload.value;
      }
    },
    setUserInfo: (state: FormState, action: PayloadAction<UserInfo>) => {
      state.user.userInfo = {
        ...action.payload,
        active: action.payload.active ? true : false,
      };
    },
    setSelectedUserGroups: (
      state: FormState,
      action: PayloadAction<string[]>
    ) => {
      state.user.groups.data = action.payload;
      state.user.groups.loading = "DONE";
    },
    setSelectedUserGroupLoading: (
      state: FormState,
      action: PayloadAction<"IDLE" | "LOADING" | "DONE">
    ) => {
      state.user.groups.loading = action.payload;
    },
    clearUserInfoData: (state: FormState) => {
      state.user.userInfo = {
        email_address: "",
        first_name: "",
        last_name: "",
        active: true,
        middle_name: "",
        role_id: "",
        user_id: null,
      };
    },
  },
});

export const reducer = formsSlice.reducer;
export const {
  updateUserSite,
  setAllFacilities,
  setSelectedUserSites,
  clearUserInfoData,
  setUserInfo,
  updateUserInfoField,
  setSelectedUserGroups,
} = formsSlice.actions;
