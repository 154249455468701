import { createAsyncThunk } from "@reduxjs/toolkit";

export const FetchAppRoles = createAsyncThunk(
  "FetchAppRoles",
  async (
    payload: { token: string; force?: boolean },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const data: any = getState();

    if (!payload.force) {
      // check if the user has already been pulled in the state and return it
      const roles = data.roles;
      if (roles.siteRoles.length && roles.userRoles.length) {
        return fulfillWithValue(roles);
      }
    }

    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/roles`,
        {
          method: "GET",
          headers: { Authorization: payload.token },
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
