import { createSlice } from "@reduxjs/toolkit";
import { AppRoleState } from "./roles.interfaces.tsx";
import { FetchAppRoles } from "./roles.thunk.tsx";

const appRoles = createSlice({
  name: "appRoles",
  initialState: {
    userRoles: [],
    siteRoles: [],
    error: null,
    loading: "IDLE",
  } as AppRoleState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchAppRoles.pending, (state) => {
        state.error = null;
        state.loading = "PENDING";
      })
      .addCase(FetchAppRoles.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = "DONE";
      })
      .addCase(FetchAppRoles.fulfilled, (state, action) => {
        state.siteRoles = action.payload.site_roles;
        state.userRoles = action.payload.user_roles;
        state.loading = "DONE";
      });
  },
});

export const reducer = appRoles.reducer;
