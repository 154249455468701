import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface RoleBasedRouteProps {
  userRoles: string[];
  allowedRoles: string[];
}

export const RoleBaseRoute = (props: RoleBasedRouteProps) => {
  return props.allowedRoles.some((role) => props.userRoles.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
