import React, { useEffect, useState } from "react";

import "./Login.scss";
import { Flex, Button, TextField, Link } from "@aws-amplify/ui-react";
import Logo from "../../../images/pfs-logo.png";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

export interface Credentials {
  email: string;
  password: string;
}

export interface LoginProps {
  onAuthLogin: () => void;
  onRegister: () => void;
}

export interface IAccountDetails {
  active: boolean;
  first_name: string;
  last_name: string;
  email_address: string;
  middle_name: string;
  userId: number;
  registered: boolean;
}

export const Login = (props: LoginProps) => {
  const navigate = useNavigate();
  const [showRegistrationScreen, setShowRegistrationScreen] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [validatingEmail, setValidatingEmail] = useState<boolean>(false);
  const [accountDetails, setAccountDetails] = useState<IAccountDetails | null>(
    null
  );
  const [validationError, setValidationError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [settingPassword, setSettingPassword] = useState<boolean>(false);
  const [showRegistrationConfirmation, setShowRegistrationConfirmation] =
    useState<boolean>(false);

  const clearState = () => {
    setShowRegistrationScreen(false);
    setEmailError("");
    setAccountDetails(null);
  };

  useEffect(() => {
    const emailRegex: any = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim()) {
      if (!emailRegex.test(email)) {
        setEmailError("Please enter a valid email address.");
        return;
      }
    }
    setEmailError("");
  }, [email]);

  const registerAccount = async () => {
    setAccountDetails(null);
    setValidatingEmail(true);
    setValidationError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts`,
        {
          method: "POST",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY as string,
          },
          body: JSON.stringify({ email_address: email }),
        }
      );
      const jsonResponse = await response.json();

      if (!response.ok) {
        setValidationError(jsonResponse.message);
      } else {
        if (jsonResponse.active || jsonResponse.registered) {
          setAccountDetails(jsonResponse);
        } else {
          setValidationError(
            "There is no active account associated with this email."
          );
        }
      }
    } catch (error) {
      setValidationError("There is no account associated with this email");
    } finally {
      setValidatingEmail(false);
    }
  };

  const checkAccountRegistrationStatus = async (jobId: string) => {
    console.log("Checking registration status for job with ID", jobId);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts/register/${jobId}`,
        {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY as string,
          },
        }
      );

      const responseJson = await response.json();
      if (["FAILED", "SUCCESS"].includes(responseJson.status)) {
        if (responseJson.status === "SUCCESS") {
          clearState();
          setSettingPassword(false);
        } else {
          console.log("Failure. Should set error to ", responseJson.message);
        }
      } else {
        return checkAccountRegistrationStatus(jobId);
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };

  const setAccountPassword = async () => {
    setSettingPassword(true);
    setPasswordError("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts/register`,
        {
          method: "POST",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY as string,
          },
          body: JSON.stringify({ ...accountDetails, password }),
        }
      );

      if (!response.ok) {
        setPasswordError("There is no account associated with this email");
      } else {
        const responseJson = await response.json();
        console.log("Registration job is", responseJson);
        checkAccountRegistrationStatus(responseJson.job_id);
      }
    } catch (error) {
      console.log("Error validating: ", error);
      setPasswordError("There is no account associated with this email");
    }
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="logo-wrapper">
          <img src={Logo} alt="logo" />
        </div>
        <span className="page-title">Facility safety authentication</span>
        {!showRegistrationScreen ? (
          <div className="login-form">
            <Flex className="vertical-padded">
              <Button
                variation="primary"
                isFullWidth
                size="large"
                backgroundColor="#8CC63F"
                onClick={() => props.onAuthLogin()}
              >
                Sign In
              </Button>
            </Flex>
            <Flex className="vertical-padded">
              <Button
                variation="primary"
                isFullWidth
                size="large"
                backgroundColor="#8CC63F"
                onClick={() => setShowRegistrationScreen(true)}
              >
                Register
              </Button>
            </Flex>
          </div>
        ) : null}

        {showRegistrationScreen && !accountDetails ? (
          <div className="registration mat-card">
            {validationError && <p className="error">{validationError}</p>}
            <p>Please enter your email address.</p>
            <TextField
              placeholder="Enter your email address"
              label="Email Address"
              type="email"
              hasError={emailError.trim() !== ""}
              disabled={validatingEmail}
              name="Email Address"
              size="large"
              value={email}
              errorMessage={emailError}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              loadingText="Validating email address..."
              onClick={registerAccount}
              isDisabled={emailError.trim() !== ""}
              isLoading={validatingEmail}
              color="white"
              backgroundColor="rgb(140, 198, 63)"
            >
              Register
            </Button>

            <p className="text-centered">
              Already Registered?{" "}
              <Link
                onClick={() => {
                  setEmail("");
                  setEmailError("");
                  setValidationError("");
                  setShowRegistrationScreen(false);
                }}
              >
                Sign In
              </Link>
            </p>
          </div>
        ) : null}

        {showRegistrationScreen && !validationError && accountDetails ? (
          <div className="registration details">
            {!accountDetails.registered && accountDetails.active ? (
              <div className="account-details">
                <div className="details-item">
                  <span className="title">First Name:</span>
                  <span className="value">{accountDetails.first_name}</span>
                </div>
                <div className="details-item">
                  <span className="title">Last Name:</span>
                  <span className="value">{accountDetails.last_name}</span>
                </div>
                <div className="details-item">
                  <span className="title">Email:</span>
                  <span className="value">{accountDetails.email_address}</span>
                </div>
                <div className="details-item flex-col">
                  <TextField
                    placeholder="Password"
                    label="Password"
                    type="password"
                    hasError={passwordError.trim() !== ""}
                    disabled={settingPassword}
                    name="Password"
                    className="password-field"
                    size="large"
                    value={password}
                    errorMessage={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    loadingText="Setting Password..."
                    onClick={setAccountPassword}
                    isDisabled={password.trim() === ""}
                    isLoading={settingPassword}
                    backgroundColor="#8CC63F"
                    variation="primary"
                    isFullWidth
                  >
                    Set Password
                  </Button>
                </div>
              </div>
            ) : null}

            {accountDetails.registered ? (
              <div className="account-details mat-card">
                <p>
                  This account is already registered. Please proceed to Sign In.
                </p>
                <div className="btn-group">
                  <Flex className="vertical-padded">
                    <Button
                      variation="primary"
                      isFullWidth
                      size="large"
                      backgroundColor="#8CC63F"
                      onClick={() => props.onAuthLogin()}
                    >
                      Sign In
                    </Button>
                  </Flex>

                  <Flex className="vertical-padded">
                    <Button
                      variation="primary"
                      isFullWidth
                      size="large"
                      backgroundColor="#8CC63F"
                      onClick={() => {
                        setEmail("");
                        setAccountDetails(null);
                        setShowRegistrationScreen(true);
                      }}
                    >
                      Register Another Account
                    </Button>
                  </Flex>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <Modal
        title="Title"
        open={showRegistrationConfirmation}
        onOk={() => {
          setShowRegistrationConfirmation(false);
          navigate(0);
        }}
        okText="OK"
      >
        <p>
          Congratulation. You account has been registered. You will be
          redirected to the login screen to access your account.
        </p>
      </Modal>

      <div className="security-message">
        <p>
          Access to this system is for authorized users only. Any unauthorized
          entry or attempt to enter is strictly forbidden and will result in
          prosecution to the maximum extent allowable by appdivcable law
        </p>

        <p>
          If you are a member of a Local Safety Council and need an account on
          the Partnership For Safety Data portal, please contact your Air
          Traffic Manager or NATCA Facility rep and he/she will initiate the
          process to generate an account for you. You may also contact the
          national program office via email (
          <a href="mailto:9-aji-ask-pfs@faa.gov" className="link">
            9-aji-ask-pfs@faa.gov
          </a>
          ) for further assistance. Include your name, email address, and
          affiliation and we will assist you.
        </p>

        <p>
          For security reasons, please Log Out and Exit your web browser when
          you are done accessing services that require authentication!
        </p>

        <p>
          For technical support, or help using this system (including password
          resets), please email Facility Safety Member Support at&nbsp;
          <a href="mailto:PFSusersupport@cssiinc.com" className="link">
            PFSusersupport@cssiinc.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Login;
