import React, { useEffect } from "react";

import "./DashboardDetails.scss";
import { Image } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import {
  clearDashboardDetails,
  fetchDashboardDetails,
} from "../../../store/dashboard.reducer.tsx";
import { DashboardItem } from "../../../interfaces/dashboard.interface.tsx";
import { PageLoader } from "../PageLoader/PageLoader.tsx";

export const DashboardElement = (props: {
  dashboard: DashboardItem;
  topic: string;
}) => {
  const navigate = useNavigate();
  const { dashboard, topic } = props;

  const goToTableauView = () => {
    const url = `/dashboard/${topic.replace("/", "")}/tableau-view`;
    navigate(url, { state: { dashboard } });
  };
  return (
    <div className={`topic ${dashboard.isAvailable ? "" : "disabled"}`}>
      <div className="logo">
        <Image
          src={dashboard.icon_url}
          alt={dashboard.dashboard_name}
          sizes="30"
        />
      </div>
      <div className="details">
        <label onClick={goToTableauView}>{dashboard.dashboard_title}</label>
        <p>{dashboard.description}</p>
        {dashboard.dashboard_title.toLowerCase().includes("cisp") && (
          <p className="note">
            <b>CISP Note</b>: If a facility does not have 2 or more
            participating airlines, then there will be no <b>CISP</b> data to
            view.
          </p>
        )}
      </div>
    </div>
  );
};

export const DashboardDetails = (props: { email: string; token: string }) => {
  const dispatch = useAppDispatch();
  const loading = useTypedSelector((state) => state.dashboard.loadingDetails);
  const error = useTypedSelector(
    (state) => state.dashboard.dashboardDetailsError
  );
  const dashboards = useTypedSelector(
    (state) => state.dashboard.dashboardDetails
  );
  const { topicType } = useParams();
  const { email, token } = props;

  useEffect(() => {
    if (loading === "IDLE") {
      dispatch(
        fetchDashboardDetails({ email, token, topic: topicType as string })
      );
    }

    return () => {
      if (loading === "DONE") {
        dispatch(clearDashboardDetails());
      }
    };
  }, [dispatch, email, loading, token, topicType]);

  if (loading !== "DONE") {
    return (
      <div className="dashboard-details">
        <PageLoader />
      </div>
    );
  }

  if (error) {
    return <h3 className="error">{error}</h3>;
  }

  return (
    <section className="dashboard-details">
      <section className="content">
        <h1 className="title">
          {topicType
            ?.replace("-", "/")
            .replace("Tracon", "TRACON")
            .replace("Artcc", "ARTCC")}{" "}
          Topics
        </h1>
        <div className="topic-container">
          {dashboards.length === 0 && (
            <p className="no-dashboard">No dashbard found</p>
          )}
          {dashboards.map((dashboard: DashboardItem) => (
            <DashboardElement
              dashboard={dashboard}
              topic={topicType as string}
              key={dashboard.dashboard_title.replace(" ", "-")}
            />
          ))}
        </div>
      </section>
    </section>
  );
};
