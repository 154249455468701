import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import TableauEmbed from "./components/Tableau/TableauEmbed";
import {
  Login,
  TopHeader,
  SecondHeader,
  Dashboard,
  DashboardDetails,
  SideNav,
  TableauView,
  UserManagement,
  UserDetails,
  AddUser,
  RoleBaseRoute,
} from "./components/tsx-components/index.tsx";
import "bootstrap/dist/css/bootstrap.min.css";
import AutoLogout from "./components/tsx-components/AutoLogout/AutoLogout.tsx";
import CopyrightModal from "./components/copyright-modal.js";
import { useAppDispatch } from "./store/app.store.tsx";
import { FetchAppRoles } from "./store/roles.thunk.tsx";

export const App = () => {
  const {
    logout,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
  } = useAuth0();
  const [token, setToken] = useState(null);
  const dispatch = useAppDispatch();
  const [openPolicy, setOpenPolicy] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const tableauUrl = process.env.REACT_APP_TABLEAU_URL;
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const tableauOptions = {
    width: "100%",
    height: "calc(100vh - 160px)",
    device: "desktop",
    toolbar: "hidden",
  };
  const adminRoles = ["Administrator", "Site Administrator"];

  const menus = [
    {
      label: "Site Administration",
      key: "siteAdministration",
      roles: adminRoles,
      url: "/users",
    },
    {
      label: "Sign Out",
      key: "signOut",
      click: () =>
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_URL_REDIRECT,
          },
        }),
    },
  ];

  const authLogin = () => {
    loginWithRedirect()
      .then((authResult) => {
        if (authResult && authResult.accessToken) {
          // Store token
          setToken(authResult.accessToken);
        }
      })
      .catch((error) => {
        console.error("Authentication error:", error);
      });
  };

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const currentToken = await getAccessTokenSilently();
          setToken(currentToken);
          dispatch(FetchAppRoles({ token: currentToken }));
        } catch (error) {
          console.log("Error fetching access token", error);
        }
      }
    };

    const fetchUserRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PFS_BASE_URL}/auth/${user.email}/roles`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        if (!response.ok) {
          setUserRoles([]);
        } else {
          const roles = await response.json();
          setUserRoles(roles);
        }
      } catch (error) {
        console.log("Failed to fetch user roles ", error);
        setUserRoles([]);
      }
    };

    fetchAccessToken();

    // if user is set then pull the roles
    if (user && user.email) {
      fetchUserRoles();
    }
  }, [isAuthenticated, getAccessTokenSilently, isLoading, user, dispatch]);

  const username = user?.email;
  if (isLoading && !token) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="full-page">
      {!isAuthenticated && <Login onAuthLogin={authLogin} />}
      {isAuthenticated && token && (
        <div className="app-container">
          <div className="top-banner">
            <AutoLogout
              timeout={1000 * 60 * process.env.REACT_APP_AUTO_LOGOUT_TIME}
            />
          </div>
          <TopHeader user={user} />
          <SecondHeader email={user.email} token={token} />
          <div className="main-wrapper">
            <nav className="side-nav">
              <SideNav user={user} menus={menus} userRoles={userRoles} />
            </nav>
            <div className="main-content">
              <Routes>
                <Route
                  path="/dashboard"
                  element={<Dashboard email={user.email} token={token} />}
                />
                <Route
                  path="/dashboard/:topicType"
                  element={
                    <DashboardDetails email={user.email} token={token} />
                  }
                />
                <Route
                  path="/dashboard/:topicType/:dashboardName"
                  element={
                    <TableauView
                      email={user.email}
                      apiEndpoint={apiEndpoint}
                      token={token}
                    />
                  }
                />
                <Route
                  element={
                    <RoleBaseRoute
                      userRoles={userRoles}
                      allowedRoles={adminRoles}
                    />
                  }
                >
                  <Route
                    path="/users"
                    element={<UserManagement token={token} />}
                  />
                </Route>
                <Route
                  element={
                    <RoleBaseRoute
                      userRoles={userRoles}
                      allowedRoles={adminRoles}
                    />
                  }
                >
                  <Route
                    exact
                    path="/users/:userId/edit"
                    element={<AddUser token={token} />}
                  />
                </Route>
                <Route
                  element={
                    <RoleBaseRoute
                      userRoles={userRoles}
                      allowedRoles={adminRoles}
                    />
                  }
                >
                  <Route
                    exact
                    path="/users/new"
                    element={<AddUser token={token} />}
                  />
                </Route>
                <Route
                  element={
                    <RoleBaseRoute
                      userRoles={userRoles}
                      allowedRoles={adminRoles}
                    />
                  }
                >
                  <Route
                    exact
                    path="/users/:userId"
                    element={<UserDetails token={token} />}
                  />
                </Route>
                <Route path="/home" element={<Navigate to="/" />} />
                <Route
                  path="*"
                  element={
                    <TableauEmbed
                      url={tableauUrl}
                      username={username}
                      options={tableauOptions}
                      token={token}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      )}
      <div className="copyright">
        <p>
          Copyright &copy; {new Date().getFullYear()} CSSI -{" "}
          <a href="https://cssiinc.com" target="_blank" rel="noreferrer">
            cssiinc.com
          </a>
        </p>
        <span className="cp-link" onClick={() => setOpenPolicy(true)} href="">
          Privacy Policy
        </span>
        <CopyrightModal
          isOpen={openPolicy}
          onRequestClose={() => setOpenPolicy(false)}
        />
      </div>
    </div>
  );
};
