// src/stateStore.ts
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import * as facilityReducer from "./facilities.reducer.tsx";
import * as dashboardReducer from "./dashboard.reducer.tsx";
import * as authenticationReducer from "./authentication.reducer.tsx";
import * as userReducer from "./user.reducer.tsx";
import * as formReducer from "./forms.reducer.tsx";
import * as groupReducer from "./groups.reducer.tsx";
import * as roleReducer from "./roles.reducer.tsx";

export const store = configureStore({
  reducer: {
    facility: facilityReducer.reducer,
    dashboard: dashboardReducer.reducer,
    authentication: authenticationReducer.reducer,
    user: userReducer.reducer,
    forms: formReducer.reducer,
    airlines: groupReducer.reducer,
    formstate: formReducer.reducer,
    roles: roleReducer.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
